import React, { useEffect } from 'react';
import { useSearchJobsMutation } from '../../features/job/jobSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import JobCard from './JobCard';
import Sidebar from './Sidebar';
import DotLoader from '../../components/dotLoader/DotLoader';

const Jobs = () => {
    /**-React Router-**/
    const { pathname } = useParams()
    const [searchParams] = useSearchParams()

    /**-variables-**/
    const type = searchParams.get('type')
    const gender = searchParams.get('g')
    const age = searchParams.get('age')
    const lc = searchParams.get('lc')
    const date = searchParams.get('date')
    const pt = searchParams.get('pt')
    const ps = searchParams.get('ps')

    /**-RTK-**/
    //mutations
    const [searchJobs, { data: jobs, isLoading: searchJobsLoading }] = useSearchJobsMutation()

    /**-useEffects-**/
    useEffect(() => {
        if (!searchJobsLoading) {
            searchJobs({ type: type || "all", gender, age, lc, date, pt, ps })
                .then(result => null)
                .catch(err => console.log(err))
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, searchParams, searchJobs])


    return (
        <div className='bg-light'>
            <div className='container'>
                <div className='row mx-0 min-vh-100 position-relative my-3'>
                    <section
                        className='col-12 col-lg-3 vh-85 overflow-auto position-sticky bg-white border-end rounded-3'
                        style={{ top: '5%' }}
                    >
                        <div className=''>
                            <div className="position-relative d-flex flex-column">
                                <div className='bg-white border-bottom position-sticky top-0 z-20'>
                                    <p className='fs-4 fw-bold m-0 p-3'>Jobs</p>
                                </div>
                                <div className="flex-grow-1 py-3">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='col-12 col-lg-9'>
                        <div className="row g-3">
                            {
                                jobs?.data?.map(job => (
                                    <div key={job._id} className="col-12 col-lg-4">
                                        <JobCard
                                            cardData={job}
                                        />
                                    </div>
                                ))
                            }
                            {
                                !jobs?.data?.length &&
                                <div className="vh-100 d-grid align-items-center bg-white rounded shadow-sm">
                                    {
                                        searchJobsLoading
                                            ?
                                            <DotLoader />
                                            :
                                            <p className='fs-4 fw-bold text-center text-secondary'>No jobs found.</p>
                                    }
                                </div>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Jobs;